@import "styles/colors";
@import "styles/breakpoints";

.container {
  width: 100%;
  padding: 30px;
  background: $WHITE;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.2rem;

  @media only screen and (min-width: $TABLET) {
    font-size: 1.4rem;
  }

  .section {
    margin-bottom: 55px;
  }

  .item:not(:first-of-type) {
    border-top: 1px dashed $GREY_MEDIUM_LIGHT;
  }

  .account {
    color: $BRAND;
    .icon {
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }

  .auth {
    margin-top: 10px;
    display: grid;
    grid-row-gap: 10px;
  }

  .button {
    width: 100%;
  }
}
