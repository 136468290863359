@import "styles/colors";
@import "styles/breakpoints";

.searchBox {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $SAND_DARKER;
  padding: 30px 30px 30px 30px;

  @include tablet {
    top: 87px;
    bottom: unset;
    padding: 36px 51px 46px 83px;
  }

  @include desktop {
    top: 129px;
    padding: 50px;
  }

  .inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $BLACK;
    padding-bottom: 15px;

    @include desktop {
      max-width: 634px;
      margin: 0 auto;
    }

    .searchInput {
      width: 100%;
      height: auto;
      border: none;
      border-radius: 0px;
      font-size: 1em;
      padding: 0 27px 0 0;

      &::placeholder {
        padding-left: 10px;
      }
    }

    .arrowIconContainer {
      position: absolute;
      right: 5px;
      width: 18px;
      height: 100%;
      cursor: pointer;
      transition: all .3s ease;
      display: flex;
      align-items: center;

      .arrowIcon {
        width: 100%;
        height: 10px;
      }
    }

    .arrowIconContainer:hover {
      width: 23px;
      right: 0;
    }
  }
}

.searchBox.noSubscribeButton {
  top: 116px;
}

.searchBox.noWidget {
  position: relative;
  top: 0;
}
