@import "styles/colors";

.container {
  padding: 10px 14px;
  border-radius: 5px;
  font-weight: 300;
}

.isSelected {
  background-color: $BRAND_LIGHT;
  color: red;
  font-weight: 600;
}

.children {
  .container {
    padding-left: 30px;
  }
}
