@import "styles/colors";
@import "styles/breakpoints";

.searchButton {
  color: $BRAND;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    @include desktop {
      width: 22px;
      height: 22px;
    }
  }
}
