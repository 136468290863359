.container {
  width: 25px;
  height: 19px;
  background-color: transparent;
  cursor: pointer;

  .line {
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: black;
    border-radius: 2px;
    transform-origin: 1px center;
    transition: all .3s linear;
  }
  .one {
    top: 0;
  }
  .two {
    top: 50%;
    transform: translateY(-50%);
  }
  .three {
    bottom: 0;
  }
}
.invert:not(.isOpen) {
  .line {
    background-color: white;
  }
}
.isOpen {
  .one {
    transform: rotate(45deg);
  }
  .two {
    opacity: 0;
  }
  .three {
    transform: rotate(-45deg);
  }
}
.isHome {
}
