@import "styles/colors";
@import "styles/breakpoints";

$NAV_HEIGHT: 87px;
$DESKTOP_NAV_HEIGHT: 129px;
$PREVIEW_ALERT_HEIGHT: 50px;

.container {
  font-family: Poppins;
  font-size: 1.6rem;
  font-weight: 300;
  padding-bottom: $NAV_HEIGHT;

  @media only screen and (min-width: $TABLET) {
    padding-bottom: 0;
    padding-top: $NAV_HEIGHT;

    @media only screen and (min-width: $DESKTOP) {
      padding-top: $DESKTOP_NAV_HEIGHT;
      background: $WHITE;
    }
  }

  .previewAlert {
    display: none;
    z-index: 1;
  }

  .nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    @media only screen and (min-width: $TABLET) {
      bottom: revert;
      top: 0;
    }
  }

  h1 {
    margin: 0;
    font-family: Domaine Display Narrow Test, serif;
    font-weight: 600;
    font-size: 3.6rem;
    @media only screen and (min-width: $DESKTOP) {
      font-size: 4.8rem;
    }
  }

  h2 {
    margin: 0;
    font-family: Domaine Display Narrow Test, serif;
    font-weight: 600;
    font-size: 2.6rem;
  }

  h3 {
    font-weight: 600;
    font-size: 1.6rem;
  }

  .content {
    position: relative;
    background-color: $WHITE;
    min-height: 100vh;

    @media only screen and (min-width: $TABLET) {
      min-height: revert;
    }

    .loaderContainer {
      height: 400px;
      display: grid;
      color: $BRAND;

      .loader {
        justify-self: center;
        align-self: center;
      }
    }
  }

  a:hover {
    text-decoration: underline;
  }

  .submitting {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $WHITE;
    opacity: 0.8;
  }

  .menu {
    position: fixed;
    overflow: hidden;
    top: 100%;
    bottom: -100%;
    transition: all .3s;
    @media only screen and (min-width: $TABLET) {
      top: -100%;
      bottom: 100%;
      @media only screen and (min-width: $DESKTOP) {
        display: none;
      }
    }
  }
}

.container.preview {
  padding-top: $PREVIEW_ALERT_HEIGHT;

  @media only screen and (min-width: $TABLET) {
    padding-top: $NAV_HEIGHT + $PREVIEW_ALERT_HEIGHT;

    @media only screen and (min-width: $DESKTOP) {
      padding-top: $DESKTOP_NAV_HEIGHT + $PREVIEW_ALERT_HEIGHT;
    }
  }

  .previewAlert {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: $PREVIEW_ALERT_HEIGHT;
  }

  .nav {
    @media only screen and (min-width: $TABLET) {
      top: $PREVIEW_ALERT_HEIGHT;
    }
  }
}

.container.isMenuOpen {
  position: fixed;
  width: 100%;

  .menu {
    top: 0;
    bottom: $NAV_HEIGHT;
    @media only screen and (min-width: $TABLET) {
      top: $NAV_HEIGHT;
      bottom: 0;
    }
  }
}

.container.preview.isMenuOpen {
  .menu {
    top: $PREVIEW_ALERT_HEIGHT;
    bottom: $NAV_HEIGHT;
    @media only screen and (min-width: $TABLET) {
      top: calc(#{$PREVIEW_ALERT_HEIGHT} + #{$NAV_HEIGHT});
      bottom: 0;
    }
  }
}

.container:not(.fontsLoaded) {
  display: none;
}
