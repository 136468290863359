@import "styles/colors";
@import "styles/breakpoints";

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  @include tablet {
    z-index: 0;
  }

  .backgroundClickSurface {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $BLACK;
    opacity: 0.3;
  }

}
