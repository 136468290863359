@import "styles/colors";
@import "styles/breakpoints";

.container {
  position: relative;
  background-color: $WHITE;
  padding: 25px 30px;
  z-index: 1;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.27);

  @media only screen and (min-width: $TABLET) {
    text-align: center;
  }
  @media only screen and (min-width: $DESKTOP) {
    padding: 25px 80px 15px 80px;
  }

  .logo {
    color: $BLACK;
    height: 3rem;
  }
  .logo:hover {
    color: $BRAND;
  }

  .burgerButton {
    position: absolute;
    margin-top: 5px;
    right: 30px;
    @media only screen and (min-width: $DESKTOP) {
      display: none;
    }
  }

  .burgerSearch {
    position: absolute;
    margin-top: 5px;
    right: 80px;
    @include desktop {
      display: none;
    }
  }

  .menu {
    display: none;
    margin-top: 10px;
    @media only screen and (min-width: $DESKTOP) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }
    .menuItems {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .menuItem {
      white-space: nowrap;
      margin-right: 45px;
    }
    .menuItem:hover {
      text-decoration: none;
      color: $BRAND;
    }
    .menuItem.isSelected {
      font-weight: 600;
      color: $BRAND;
    }
    .menuItem.isSelected:after {
      content: " ";
      display: block;
      height: 3px;
      background: $BRAND;
    }
    .login {
      color: $BRAND;
      margin-right: 40px;
      font-weight: 500;
      .icon {
        vertical-align: middle;
        margin-left: 15px;
        margin-bottom: 3px;
      }
    }

    .button {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    // style for dividers around About Us and Contact Us
    .divider {
      border: solid 1px $GREY_MEDIUM_LIGHT;
      height: 20px;
    }
  }

  .menuSearch {
    display: none;
    @include desktop {
      display: inline-block;
      vertical-align: middle;
      margin-left: 32px;
    }
  }
}
